export const environment = {
  name: 'production-na',
  region: 'NA',
  production: true,
  appUrl: 'https://emissions-check.production.zonarsystems.net',

  apiBase: {
    url: 'https://emissions-check-api.production.zonarsystems.net/api/v1'
  },

  geocoderApi: {
    url: 'https://api.zonarsystems.net'
  },

  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net'
  },
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'production',

  auth: {
    clientID: 'fCcQjgV98BCxLvhGrLsTv8UzvdgYOWeI',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    // These come from the Core APIs as your application is built.
    applicationId: 'f3b0567d-d78b-48e7-b6d6-7b3a0f552983',
    defaultZonarRole: '145d37df-f188-4b51-84d3-40353b9b0bad',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_006421b6_cff8_4453_a22c_f4610d411224_86757de'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'b98eb354-3b80-421c-a916-54dda63afa57',
    clientToken: 'pub6a4c810b136693403837854ef96b11f6',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'production'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'it-IT', 'fr-FR', 'es-ES'],
    defaultLanguage: 'en-US'
  },

  userPreferenceApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  }
};
